import { ref } from "vue";
import { usePopper } from "~/utils/usePopper";

export const useScreeningCinemaHallTooltip = (popperOptions) => {
  let _timer = null;
  const TIMEOUT = 100;
  const hoveredCinemaHall = ref(null);

  const [hoverCinemaHallTriggerElement, hoveredCinemaHallPopup] =
    usePopper(popperOptions);

  const setActiveCinemaHallTooltip = ({ movie, event }) => {
    clearTimeout(_timer);
    (_timer = setTimeout(() => {
      hoveredCinemaHall.value = {
        movie,
        hoverCinemaHallTriggerElement: event?.target,
      };

      hoverCinemaHallTriggerElement.value = event?.target;
    })),
      0;
  };

  const closeCinemaHallTooltip = () => {
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      hoveredCinemaHall.value = null;
    }, TIMEOUT);
  };

  const holdCinemaHallTooltip = () => {
    clearTimeout(_timer);
  };

  onUnmounted(() => {
    clearTimeout(_timer);
    _timer = null;
  });
  return {
    hoveredCinemaHall,
    hoveredCinemaHallPopup,
    setActiveCinemaHallTooltip,
    closeCinemaHallTooltip,
    holdCinemaHallTooltip,
  };
};
